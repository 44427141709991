.fab-container {
    /* position: relative; */
    width: 100px;
    height: 100px;
    margin: 20px 10px;
    /* bottom: 0; */
    position: fixed; /* FAB is now fixed on the screen */
    z-index: 100; /* Ensure it stays above other content */
    bottom: 20px; /* Distance from the bottom of the screen */
    right: 20px; /* Distance from the right side of the screen */
}

.fab-main {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s linear;
}

.fab-container.hide {
    opacity: 0;
    transform: translateY(100px);
    /* Move FAB out of view when hidden */
    pointer-events: none;
    /* Disable interaction when hidden */
}

.fab-main.open {
    transform: rotate(360deg);

    /* Rotate FAB when open */
}

.fab-button {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s ease;
}

.fab-button.show {
    opacity: 1;
    transform: scale(1);
}

.fab-button.phone {
    bottom: calc(-10px + 0px * sin(0deg));
    right: calc(10px + 40px * cos(0deg));
    transition-delay: 0.1s;
}

.fab-button.edit {
    display: none;
    bottom: calc(20px + 40px * sin(90deg));
    right: calc(20px + 80px * cos(90deg));
    transition-delay: 0.2s;
}

.fab-button.camera {
    bottom: calc(45px + 80px * sin(180deg));
    right: calc(60px + 80px * cos(180deg));
    transition-delay: 0.3s;
}

.fab-button.search {
    bottom: calc(40px + 0px * sin(150deg));
    right: calc(35px + 10px * cos(270deg));
    transition-delay: 0.4s;
}

.fab-icon {
    font-size: 20px;
    color: black;
}

.fab-button:hover,
.fab-main:hover {
    background-color: #f0f0f0;
}