/* ReviewForm.css */
.review-form-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
    font-family: Arial, sans-serif;
}
.fb-form {
    /* width: 300px;
    top: 50%;
    left: 50%; */
    position: relative;
    /* transform: translateY(-50%) translateX(-50%); */
}
.fb-form h2 {
    text-align: center;
    font-size: 2.1em;
    color: #111;
    /* font-size: 20px; */
    font-weight: 700;
    padding-bottom: 20px;
}
.fb-form input {
    margin: 15px 0;
    background-color: #e8e5e5;

}
.fb-form input[type="submit"] {
    width: 10rem;
    background:linear-gradient(197deg, rgb(49, 41, 105), rgba(0, 0, 0, 0.54));
    margin-top: -20px;
    color: #fff;
    font-weight: 700;
}
.fb-form input[type="submit"]:hover{
    border: none !important;
}
.fb-form textarea {
    height: 100px;
    background-color: #e8e5e5;
}
.rating {
    /* margin: 15px auto; */
    transform: rotateY(180deg);
    width: 100%;
}
.rating .fa-star {
    font-size: 2em;
    cursor: pointer;
    /* margin: 0 5.1%; */
    transition: all 0.5s ease;
    color: #111;
}
.rating .fa-star.active-rating {
    transition: all 0.5s ease;
    animation: rating-highlight 0.5s ease forwards;
}
.rating .fa-star.active-rating:nth-child(5) {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star.active-rating:nth-child(1) {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star.active-rating:nth-child(1) ~ .fa-star {
    transition: all 0.5s ease;
    color: yellow;
}
.rating .fa-star.active-rating:nth-child(2) {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star.active-rating:nth-child(2) ~ .fa-star {
    transition: all 0.5s ease;
    color: yellow;
}
.rating .fa-star.active-rating:nth-child(3) {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star.active-rating:nth-child(3) ~ .fa-star {
    transition: all 0.5s ease;
    color: yellow;
}
.rating .fa-star.active-rating:nth-child(4) {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star.active-rating:nth-child(4) ~ .fa-star {
    transition: all 0.5s ease;
    color: yellow;
}
.rating .fa-star:hover:nth-child(5) {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star:hover:nth-child(1) {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star:hover:nth-child(1) ~ .fa-star {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star:hover:nth-child(2) {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star:hover:nth-child(2) ~ .fa-star {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star:hover:nth-child(3) {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star:hover:nth-child(3) ~ .fa-star {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star:hover:nth-child(4) {
    color: yellow;
    transition: all 0.5s ease;
}
.rating .fa-star:hover:nth-child(4) ~ .fa-star {
    color: yellow;
    transition: all 0.5s ease;
}
@keyframes rating-highlight {
    0% {
        transform: rotate(-10deg) scale(1.2);
   }
    20% {
        transform: rotate(10deg) scale(0.8);
   }
    50% {
        transform: rotate(-5deg) scale(1.1);
   }
    80% {
        transform: rotate(5deg) scale(0.9);
   }
    100% {
        transform: rotate(0deg) scale(1);
   }
}
